import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import './index.css'
import '../components/Navigation'
import Layout from '../components/Layout'

export default class Template extends React.Component {
  componentDidMount() {
    const imageWrappers = document.querySelectorAll('.image-row-wrapper')
    imageWrappers.forEach(wrapper => {
      const imageNode = wrapper.querySelector('img')
      wrapper.style.flex = `${imageNode.width / imageNode.height}`
    })
  }

  render() {
    const { markdownRemark, site } = this.props.data // data.markdownRemark holds our post data
    const { frontmatter, html } = markdownRemark
    const { image, title, date } = frontmatter

    return (
      <Layout metadata={site.siteMetadata} title={title}>
        <div className="blog-post">
          {image && (
            <Img
              className="cover-image"
              alt=""
              fluid={image.childImageSharp.fluid}
            />
          )}
          <h1>{title}</h1>
          <h4>{date}</h4>
          <div
            className="blog-post-content"
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </div>
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query($path: String!) {
    site {
      siteMetadata {
        title
        url
        description
      }
    }
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        image {
          childImageSharp {
            fluid(maxWidth: 660) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
